<template>
  <div>
    <div
      class="g-recaptcha col-sm-5"
      v-if="$env && $env && $env.VUE_APP_GOOGLE_RECAPTCHA_SITE_ID"
      :th:attr="'data-sitekey=' + $env.VUE_APP_GOOGLE_RECAPTCHA_SITE_ID"
    ></div>
    <div v-if="showLegend">
      <span id="captchaError" class="alert alert-danger col-sm-4" style="display:none"></span>
      This site is protected by reCAPTCHA Enterprise and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </div>
  </div>
</template>

<script>
export default {
  name: "GoogleReCaptcha",
  props: {
    showLegend: {
      type: Boolean,
      default: true
    }
  }
};
</script>
