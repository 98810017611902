<template>
  <v-text-field
    v-model="currencyValue"
    v-bind="$attrs"
    v-on="$listeners"
    v-currency="options"
    :rules="rulesArray"
    ref="currencyField"
  ></v-text-field>
</template>

<script>
import { parse, setValue } from "vue-currency-input";

export default {
  name: "CurrencyField",
  data: () => ({
    currencyValue: "",
    rulesArray: []
  }),
  props: {
    value: String,
    country: Object,
    rules: Array,
    options: {
      type: Object,
      default: () => {
        return { currency: "USD", locale: "en" };
      }
    }
  },
  methods: {
    onInput(obj) {
      if (obj && obj.length > 0) {
        let parsedAmount = parse(obj, this.options);
        this.$emit("input", parsedAmount.toString());
        this.$emit("input", obj);
      } else {
        this.$emit("input", obj);
      }
    },
    assignOptions() {
      if (this.country) {
        this.options.currency = this.country.currencyName;
        this.options.locale = this.country.localeLanguage;
      }
    },
    resetRulesArray() {
      this.rulesArray = [];
      if (this.rules) {
        this.rulesArray = this.rulesArray.concat(this.rules);
      }
      this.rulesArray = this.rulesArray.concat(this.validate);
    },
    validate(amount) {
      if (amount) {
        let parsedAmount = parse(amount, this.options);
        if (parsedAmount > 9999999999) {
          return "Invalid amount, must be less than 10,000,000,000";
        }
      }
      return true;
    }
  },
  watch: {
    country: function() {
      this.assignOptions();
    },
    currencyValue: {
      deep: true,
      handler(v) {
        this.onInput(v);
      }
    },
    value: {
      deep: true,
      handler(v) {
        if (v != this.currencyValue) {
          setValue(this.$refs["currencyField"], v);
        }
      }
    }
  },
  mounted() {
    this.currencyValue = this.value;
    this.assignOptions();
    this.resetRulesArray();
  }
};
</script>
