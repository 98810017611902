<template>
  <div>
    <span class="headline">{{ description }}</span>
    <v-row>
      <v-col>
        <v-radio-group v-model="selectedOptionItem">
          <v-radio v-for="optionItem in optionList" :key="optionItem" :value="optionItem" :label="option"> </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "QuestionField",
  data: () => ({
    selectedOptionItem: null
  }),
  props: {
    value: [Object, String],
    optionList: Array,
    description: String,
    rules: Array
  },
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    validate() {}
  },
  mounted() {}
};
</script>
