<template>
  <div>
    <v-select
      v-if="claimField.csv && claimField.csv.length > 0 && showField()"
      :value="value"
      @input="onInput"
      v-bind="$attrs"
      v-on="$listeners"
      :items="csvValues(claimField)"
      :class="claimField.required ? 'required' : ''"
      :rules="claimField.required ? rules.required : []"
      clearable
    />
    <CountryField
      v-else-if="
        (claimField.claimFieldType.name == 'COUNTRY_TYPE' ||
          claimField.claimFieldType.name == 'END_USER_COUNTRY_TYPE') &&
          showField()
      "
      :value="value"
      @input="onInput"
      v-bind="$attrs"
      v-on="$listeners"
      :label="claimField.name"
      :class="claimField.required ? 'required' : ''"
      :rules="claimField.required ? rules.required : []"
      :available="[promotion.country]"
      :country="promotion.country"
    />
    <CurrencyField
      v-else-if="
        (claimField.claimFieldType.name == 'INVOICE_AMOUNT_TYPE' ||
          claimField.claimFieldType.name == 'CURRENCY_TYPE') &&
          showField()
      "
      :value="value"
      @input="onInput"
      v-bind="$attrs"
      v-on="$listeners"
      :label="claimField.name"
      :class="claimField.required ? 'required' : ''"
      :rules="rules.getDynamicRules(claimField)"
      :country="promotion.country"
    />
    <DateTimePickerField
      v-else-if="claimField.claimFieldType.name == 'DATE_TYPE' && showField()"
      :value="value"
      @input="onInput"
      v-bind="$attrs"
      v-on="$listeners"
      :label="claimField.name"
      :rules="claimField.required ? rules.required : []"
      :required="claimField.required"
      :allowTextInput="false"
      format="MM/DD/YYYY"
      returnFormat="YYYY-MM-DD HH:mm:ss"
      defaultTime="00:00"
      onlyDate
    />
    <DateTimePickerField
      v-else-if="claimField.claimFieldType.name == 'INVOICE_DATE_TYPE' && showField()"
      :value="value"
      @input="onInput"
      v-bind="$attrs"
      v-on="$listeners"
      :label="claimField.name"
      :rules="claimField.required ? [v => this.validateInvoiceDate(v)] : []"
      :required="claimField.required"
      :allowTextInput="false"
      format="MM/DD/YYYY"
      returnFormat="YYYY-MM-DD HH:mm:ss"
      defaultTime="00:00"
      onlyDate
    />

    <RegionField
      v-else-if="
        (claimField.claimFieldType.name == 'REGION_TYPE' || claimField.claimFieldType.name == 'END_USER_REGION_TYPE') &&
          showField()
      "
      :value="value"
      @input="onInput"
      v-bind="$attrs"
      v-on="$listeners"
      :label="claimField.name"
      :class="claimField.required ? 'required' : ''"
      :rules="rules.getDynamicRules(claimField)"
      :country="promotion.country"
    />
    <PhoneNumberField
      v-else-if="
        (claimField.claimFieldType.name == 'PHONE_TYPE' ||
          claimField.claimFieldType.name == 'END_USER_PHONE_NUMBER1_TYPE' ||
          claimField.claimFieldType.name == 'END_USER_PHONE_NUMBER2_TYPE') &&
          showField()
      "
      :value="value"
      @input="onInput"
      v-bind="$attrs"
      v-on="$listeners"
      :label="claimField.name"
      :class="claimField.required ? 'required' : ''"
      :rules="rules.getDynamicRules(claimField)"
      :country="promotion.country"
    />
    <PostalCodeField
      v-else-if="
        (claimField.claimFieldType.name == 'POSTAL_CODE_TYPE' ||
          claimField.claimFieldType.name == 'END_USER_POSTAL_CODE_TYPE') &&
          showField()
      "
      :value="value"
      @input="onInput"
      v-bind="$attrs"
      v-on="$listeners"
      :label="claimField.name"
      :class="claimField.required ? 'required' : ''"
      :rules="rules.getDynamicRules(claimField)"
      :country="promotion.country"
    />
    <EmailField
      v-else-if="
        (claimField.claimFieldType.name === 'EMAIL_TYPE' || claimField.claimFieldType.name === 'END_USER_EMAIL_TYPE') &&
          showField()
      "
      @input="onInput"
      v-bind="$attrs"
      v-on="$listeners"
      :value="value"
      :label="claimField.name"
      :class="claimField.required ? 'required' : ''"
      :rules="rules.getDynamicRules(claimField)"
      :notRequired="!claimField.required"
    />
    <OrganizationField
      v-else-if="claimField.claimFieldType.name == 'ORGANIZATION' && showField()"
      @input="onInput"
      v-bind="$attrs"
      v-on="$listeners"
      :value="value"
      :searchFilterJson="claimField.searchFilterJson ? JSON.parse(claimField.searchFilterJson) : {}"
      :showBottomMessage="true"
      :other="determineOther()"
      :showCityRegionPostalCode="true"
      :hint="claimField.hint && claimField.hint != '' ? claimField.hint : claimField.description"
      :persistent-hint="claimField.persistentHint ? claimField.persistentHint : false"
      :isAutocomplete="isOrganizationFieldAutocomplete"
      :publicField="publicField"
      :class="claimField.required ? 'required' : ''"
      :rules="rules.getDynamicRules(claimField)"
    />
    <QuestionField
      v-else-if="claimField.claimFieldType.name == 'RADIO_BUTTON_QUESTION' && showField()"
      @input="onInput"
      :value="value"
      :description="claimField.description"
      :optionList="claimField.optionList.split(',')"
      :class="claimField.required ? 'required' : ''"
      :rules="rules.getDynamicRules(claimField)"
    />
    <div v-else-if="claimField.claimFieldType.name == 'UPLOAD_TYPE' && showField()">
      {{ claimFieldUploadValue ? "Current File: " : "" }}
      <a target="_blank" :href="claimFieldUploadValue.href ? claimFieldUploadValue.href : null"
        >{{
          claimFieldUploadValue && claimFieldUploadValue.originalFilename
            ? claimFieldUploadValue.originalFilename.split(".")[0]
            : ""
        }}
      </a>
      <UploadField
        @input="onInput"
        :value="value"
        :description="claimField.description"
        :class="claimField.required ? 'required' : ''"
        :rules="claimField.required ? rules.required : []"
        :public="publicField"
        :limitNumberFile="1"
        :disabled="isDisabled"
      />
    </div>
    <v-text-field
      v-else-if="showField()"
      :value="value"
      @input="onInput"
      v-bind="$attrs"
      v-on="$listeners"
      :label="claimField.name"
      :hint="claimField.description"
      :class="claimField.required ? 'required' : ''"
      :rules="rules.getDynamicRules(claimField)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../services/api.service";
import UtilService from "../../services/util.service";
import CountryField from "./CountryField";
import CurrencyField from "./CurrencyField";
import DateTimePickerField from "./DateTimePickerField";
import PhoneNumberField from "./PhoneNumberField";
import PostalCodeField from "./PostalCodeField";
import RegionField from "./RegionField";
import EmailField from "./EmailField";
import OrganizationField from "./OrganizationField";
import QuestionField from "./QuestionField";
import UploadField from "./UploadField";
import moment from "moment-timezone";

export default {
  name: "ClaimField",
  props: {
    value: [Object, String],
    claimField: Object,
    promotion: Object,
    isOrganizationFieldAutocomplete: {
      type: Boolean,
      default: false
    },
    publicField: {
      type: Boolean,
      default: false,
      required: false
    },
    isDisabled: { type: Boolean, default: false, required: false },
    showOtherItemOrganization: { type: Boolean, default: undefined, required: false },
  },
  components: {
    CountryField,
    CurrencyField,
    DateTimePickerField,
    PhoneNumberField,
    PostalCodeField,
    RegionField,
    EmailField,
    OrganizationField,
    QuestionField,
    UploadField
  },
  data: () => ({
    rules: {
      required: [v => !!v || "Field is required"],
      getDynamicRules(claimField) {
        const dynamicRules = [];
        if (claimField.required) {
          dynamicRules.push(v => !!v || "Field is required");
        }
        if (claimField.regex) {
          const regex = new RegExp(claimField.regex);
          dynamicRules.push(v => {
            if (regex.test(v)) {
              return true;
            } else {
              return claimField.hint || "Some special characters are not allowed. Please remove them.";
            }
          });
        }
        return dynamicRules;
      }
    },
    promotionDeadlines: [],
    loadedPromotionDeadlines: false,
    claimFieldUploadValue: {}
  }),
  mounted() {
    if (this.claimField && this.claimField.claimFieldType.name == "UPLOAD_TYPE") {
      this.claimFieldUploadValue = this.getClaimFieldUploadValue();
    }
    if (
      this.claimField &&
      this.claimField.claimFieldType.name == "INVOICE_DATE_TYPE" &&
      this.promotion &&
      (!this.promotionDeadlines || this.promotionDeadlines.length == 0)
    ) {
      return this.fetchPromotionDeadlines(this.promotion);
    }
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedParticipant"])
  },
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    csvValues(claimField) {
      let items = [];
      if (claimField.csv) {
        let parts = claimField.csv.split(",");
        items = parts.map(part => {
          return { text: part.trim(), value: part.trim() };
        });
      }
      return items;
    },
    async getClaimFieldUploadValue() {
      if (this.value && this.value.existing && this.value.existing.length > 0) {
        let value = this.value.existing[0].id;
        await ApiService.get("/api/uploads/" + value).then(({ data }) => {
          this.$set(this, "claimFieldUploadValue", data);
        });
      } else {
        console.error("No existing upload data found.");
      }
    },
    async fetchPromotionDeadlines(promotion) {
      if (promotion.promotionDeadlines) {
        this.promotionDeadlines = promotion.promotionDeadlines;
        this.loadedPromotionDeadlines = true;
        this.validateInvoiceDate(this.value);
      } else {
        return ApiService.post("/api/promotionDeadlines/search", { promotion: { id: promotion.id } }).then(
          ({ data }) => {
            this.$set(this, "promotionDeadlines", data);
            this.loadedPromotionDeadlines = true;
            this.validateInvoiceDate(this.value);
          }
        );
      }
    },
    validateInvoiceDate(v) {
      // if v is not set, then don't validate it (rely on required validation for that)
      if (!v) {
        return true;
      }

      if (!this.loadedPromotionDeadlines) {
        return true;
      }

      let date = UtilService.parseDateClient(v, "MM/DD/YYYY", this.selectedClient);
      let validationErrors = [];

      let dateBetweenPromotionPeriod = false;

      if (date.isValid()) {
        if (this.promotionDeadlines) {
          this.promotionDeadlines.forEach(promotionDeadline => {
            let startDate = promotionDeadline.effectiveDate
              ? moment.utc(promotionDeadline.effectiveDate)
              : moment.utc(this.promotion.effectiveDate);
            let endDate = promotionDeadline.expirationDate
              ? moment.utc(promotionDeadline.expirationDate)
              : moment.utc(this.promotion.expirationDate);
            let today = moment.utc();
            let claimDeadline = moment.utc(promotionDeadline.submissionDeadlineDate);
            if (date.isBetween(startDate, endDate, null, "[]")) {
              dateBetweenPromotionPeriod = true;
              if (today.isAfter(claimDeadline)) {
                validationErrors.push("The submission deadline has past for this invoice date");
              }
            }
          });

          if (!dateBetweenPromotionPeriod) {
            validationErrors.push("Invoice date is outside of the promotional period");
          }
        }

        let promotionStartDate = moment.utc(this.promotion.effectiveDate);
        let promotionEndDate = moment.utc(this.promotion.expirationDate);
        if (!date.isBetween(promotionStartDate, promotionEndDate, null, "[]")) {
          validationErrors.push("Invoice date is outside of the promotional period");
        }
      }

      if (validationErrors.length > 0) {
        return validationErrors[0];
      } else {
        return true;
      }
    },
    showField() {
      if (!this.selectedParticipant) {
        return !this.claimField.hidden;
      }
      return !this.claimField.hidden || this.selectedParticipant.user.gappInternalUser;
    },
    determineOther() {
      if (this.showOtherItemOrganization !== undefined) {
        return this.showOtherItemOrganization;
      }
      return (
        this.claimField.name &&
        (this.claimField.name.trim() === "Sony Dealer" || this.claimField.name.trim() === "Reseller")
      );
    }
  },
  watch: {
    promotionDeadlines: {
      deep: true,
      handler() {
        this.validateInvoiceDate();
      }
    }
  }
};
</script>
