<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="countries"
    item-value="name"
    :item-text="render"
    clearable
    return-object
  >
    <template v-slot:selection="data">{{ render(data.item) }}</template>
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "CountryField",
  props: {
    value: [Object, String],
    available: Array,
    country: Object,
    program: Object
  },
  data: () => ({
    defaultCountries: [],
    countries: []
  }),
  methods: {
    render(item) {
      let returnValue = "";
      if (item) {
        if (this.$vuetify.breakpoint.name == "xs" || this.$vuetify.breakpoint.name == "sm") {
          returnValue = item.name;
        } else {
          returnValue = item.name + " - " + item.description;
        }
      }
      return returnValue;
    },
    onInput(obj) {
      this.$emit("input", obj);
    },
    fetchData() {
      this.countries = [];
      if (this.available) {
        this.countries = this.available;
      } else if (this.program && this.program.programCountries) {
        this.countries = this.program.programCountries.map(programCountry => programCountry.country);
      } else {
        this.countries = this.defaultCountries;
      }
    }
  },
  watch: {
    program() {
      this.fetchData();
    },
    available() {
      this.fetchData();
    },
    country: {
      immediate: true,
      deep: true,
      handler(v) {
        if (!this.value) this.$emit("input", v);
      }
    }
  },
  mounted() {
    ApiService.get("/api/types/address/countries").then(({ data }) => {
      this.defaultCountries = data;
      this.fetchData();
    });
  }
};
</script>
