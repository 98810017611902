<template>
  <div>
    <v-text-field
      v-if="mask"
      @input="onInput"
      v-bind="$attrs"
      v-on="$listeners"
      v-mask="{ mask: [...mask], tokens: tokens }"
      :rules="rulesArray"
    ></v-text-field>
    <v-text-field
      v-else
      @input="onInput"
      v-bind="$attrs"
      v-on="$listeners"
      type="tel"
      :rules="rulesArray"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: "PhoneNumberField",
  data: () => ({
    mask: null,
    rulesArray: [],
    tokens: {
      "#": { pattern: /\d/ },
      X: { pattern: /[0-9a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
      S: { pattern: /[a-zA-Z]/ },
      A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
      a: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
      "!": { escape: true }
    }
  }),
  props: {
    country: Object,
    rules: Array
  },
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    validate(phoneNumber) {
      if (this.country && this.country.phoneRegex && !!phoneNumber) {
        let regex = RegExp(this.country.phoneRegex);
        if (!regex.test(phoneNumber)) {
          return "Invalid " + this.country.name + " Phone Number";
        }
      }
      return true;
    },
    resetRulesArray() {
      this.rulesArray = [];
      if (this.rules) {
        this.rulesArray = this.rulesArray.concat(this.rules);
      }
      if (this.country && this.country.phoneMask) {
        this.mask = this.country.phoneMask;
        this.rulesArray = this.rulesArray.concat(this.validate);
      }
    }
  },
  watch: {
    country: function() {
      if (this.country && this.country.phoneMask) {
        this.mask = this.country.phoneMask;
      }
      this.resetRulesArray();
    }
  },
  mounted() {
    this.resetRulesArray();
  }
};
</script>
