<template>
  <v-select
    :value="value"
    :label="regionLabel"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="regions"
    item-value="name"
    :item-text="render"
    :clearable="clearable"
    return-object
  >
    <template v-slot:selection="data">{{ render(data.item) }}</template>
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "RegionField",
  props: {
    value: [Object, String],
    country: Object,
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    regions: []
  }),
  methods: {
    render(item) {
      let returnValue = "";
      if (item) {
        if (this.$vuetify.breakpoint.name == "xs" || this.$vuetify.breakpoint.name == "sm") {
          returnValue = item.isoAlpha2;
        } else {
          returnValue = item.isoAlpha2 + " - " + item.description;
        }
      }
      return returnValue;
    },
    onInput(obj) {
      this.$emit("input", obj);
    },
    fetchData() {
      if (this.country && this.country.name) {
        ApiService.get("/api/types/address/regions/country/" + this.country.name).then(({ data }) => {
          this.regions = data;
        });
      }
    }
  },
  watch: {
    country() {
      this.fetchData();
    }
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    regionLabel() {
      if (this.country) {
        return this.country.regionName;
      }
      return "Region";
    }
  }
};
</script>
